<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        Nombre de voyageurs transportés      </b-card-title>
      <!-- <b-dropdown
        text="Choisir une periode"
        variant="transparent"
        class="chart-dropdown"
        right
        no-caret
        toggle-class="p-0 mb-25"
        size="sm"
      >
        <b-dropdown-item
          v-for="day in chartData.lastDays"
          :key="day"
        >
          {{ day }}
        </b-dropdown-item>
      </b-dropdown> -->
    </b-card-header>

    <b-card-body>
      <!-- apex chart -->
      <div
        v-if="false"
        class="row"
      >
        <div class="col-lg-6">
          <h5>Par département</h5>
          <vue-apex-charts
            v-if="!isLoadingDepartements"
            type="pie"
            height="325"
            class="mt-2 mb-1"
            :options="departementsPie.chartOptions"
            :series="departementsPie.series"
          />
        </div>
        <div class="col-lg-6">
          <h5>Par communes</h5>
          <vue-apex-charts
            v-if="!isLoadingCommunes"
            type="pie"
            height="325"
            class="mt-2 mb-1"
            :options="communesPie.chartOptions"
            :series="communesPie.series"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <b-table
            responsive
            :items="getTableData"
            :fields="tableColumns"
            :busy="isLoadingTableData"
            show-empty
            empty-text="Aucune donnée trouvée"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle" />
              </div>
            </template>
            <template #cell(nombreEntrees)="data">
              <div class="">
                <b-badge variant="light-success">
                  {{ data.item.nombreEntrees }}
                </b-badge>
              </div>
            </template>
            <template #cell(nombreSorties)="data">
              <div class="">
                <b-badge variant="light-danger">
                  {{ data.item.nombreSorties }}
                </b-badge>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BTable,
  BFormSelect,
  BFormInput,
  BSpinner,
  BBadge,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { mapActions, mapState } from 'vuex'
// store modules and vuex utilities
import etablissementsStroreModule from '@/store/etablissements'
import paramsStoreModule from '@/store/params'
import statisticsStoreModule from '@/store/statistics'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

import utilsService from '@/services/utils/utils.service'

import { listMoyensDeTransport as moyensDeTransport } from '@/services/utils/constants'

const { turnToSnakeCase, turnToCamelCase } = utilsService

const listMoyensDeTransport = moyensDeTransport.map(e => ({
  id: turnToSnakeCase(e),
  name: e,
  [turnToCamelCase(`nombre ${e}`)]: 0,
  nombrePlacesDisponibles: 0,
  nombreVoyageursTransporter: 0,
}))

export default {
  components: {
    BSpinner,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    VueApexCharts,
    BTable,
    BFormSelect,
    BFormInput,
    BBadge,
  },
  props: {
    tableDatas: {
      type: Array,
      default: () => listMoyensDeTransport,
    },

    isLoadingTableData: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const requiredStoreModules = [
      { path: 'etablissements', module: etablissementsStroreModule },
      { path: 'params', module: paramsStoreModule },
      { path: 'statistics', module: statisticsStoreModule },
    ]

    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules }
  },
  data() {
    return {
      listMoyensDeTransport,
      // isLoadingTableData: false,

      // tableDatas: {
      //   departements: [],
      //   communes: [],
      //   arrondissements: [],
      //   villages: [],
      // },
      tableColumns: [
        { key: 'name', label: 'Moyen de transport', sortable: true },
        { key: 'nombreVoyageursTransporter', label: 'Nombre de voyageurs transportés', sortable: true },

      ],
    }
  },
  watch: {
    tableDatas: {
      deep: true,
      immediate: true,
      handler(val, old) {
        // console.log('val :>> ', val);
        this.listMoyensDeTransport = [...val]
        // this.filteredTableDatas[this.selectedTypeLocalisation] = val[this.selectedTypeLocalisation]
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },
  async mounted() {
  },
  methods: {
    ...mapActions('params', {
      action_loadDepartments: 'loadDepartments',
      action_loadCommunes: 'loadCommunes',
      action_loadArrondissements: 'loadArrondissements',
      action_loadNeighborhoods: 'loadNeighborhoods',
    }),
    ...mapActions('statistics', {
      action_getStatisticsAgencesVoyageToursOperators:
        'getStatisticsAgencesVoyageToursOperators',
    }),
    ...mapActions('etablissements', {
      action_getListTypesEtablissement: 'getListTypesEtablissement',
    }),
    localFilter() {
      const query = this.localSearchQuery.toLowerCase()
      this.filteredTableDatas[this.selectedTypeLocalisation] = this.tableDatas[
        this.selectedTypeLocalisation
      ].filter(e => {
        if (!query) return true
        return e.name.toLowerCase().includes(query)
      })
      this.filteredTableDatas[this.selectedTypeLocalisation] = this.filteredTableDatas[this.selectedTypeLocalisation].filter(e => true)
    },
    getRandomInt(min = 10, max = 1000) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min + 1)) + min
    },

  },
  computed: {
    getTableData() {
      return this.listMoyensDeTransport
      return this.filteredTableDatas[this.selectedTypeLocalisation]
    },
  },
}
</script>
